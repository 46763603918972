
@mixin btn($style: 'default', $size: '', $outline: false) {
	display: inline-block;
	font-weight: $btn-font-weight;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	border: $input-btn-border-width solid transparent;

	@if $size == 'lg' {
		@include button-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, $font-size-lg, $line-height-lg, $btn-border-radius-lg);
	}

	@if $size == 'sm' {
		@include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $line-height-sm, $btn-border-radius-sm);
	}

	@if $size != 'lg' and $size != 'sm' {

		@include button-size($input-btn-padding-y, $input-btn-padding-x, $font-size-base, $input-btn-line-height, $btn-border-radius);
	}

	@include transition($btn-transition);

	// Share hover and focus styles
	@include hover-focus {
		text-decoration: none;
	}
	&:focus,
	&.focus {
		outline: 0;
		box-shadow: $btn-focus-box-shadow;
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		opacity: .65;
		@include box-shadow(none);
	}

	&:active,
	&.active {
		background-image: none;
		@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
	}

	$color: map-get($theme-colors, $style);

	@if $outline {
		@include button-outline-variant($color, #fff);
	}
	@else {
		@include button-variant($color, $color);
	}
}

.btn.btn-round {
	border-radius: $btn-border-radius*5;
}

.btn-sm.btn-round {
	border-radius: $btn-border-radius-sm*5;
}

.btn-lg.btn-round {
	border-radius: $btn-border-radius-lg*5;
}

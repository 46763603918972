
//	.border-top-{$theme-colors}
//	.border-bottom-{$theme-colors}

//	.border-sm
//	.border-top-sm
//	.border-bottom-sm
//	.border-md
//	.border-top-md
//	.border-bottom-md
//	.border-lg
//	.border-top-lg
//	.border-bottom-lg

//	.border-solid
//	.border-dotted
//	.border-double
//	.border-dashed

@each $color, $value in $theme-colors {

  .border-top-#{$color} {
    border-top: 1px solid $value !important;
  }

  .border-bottom-#{$color} {
    border-bottom: 1px solid  $value !important;
  }
}

@each $key, $val in $border-sizes {

	.border-#{$key} {
		border-width: $val !important;
	}

	.border-top-#{$key} {
		border-top-width: $val !important;
	}

	.border-bottom-#{$key} {
		border-bottom-width: $val !important;
	}
}

//
// BORDER STYLE
//

.border-solid {
    border-style: solid;
}

.border-dotted {
    border-style: dotted;
}

.border-double {
    border-style: double;
}

.border-dashed {
    border-style: dashed;
}


//
// adds simple outline with extra space
//
@mixin edging-simple($border-color: $gray-200, $border-radius: $border-radius) {
	padding: 4px;
	border: 1px solid $border-color;
	border-radius: $border-radius;

	& > a, & > span {
		overflow: hidden;
		display: block;
		max-height: 100%;
	}

	& > img,
	& > a > img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
	}
}

.edging-simple {
	@include edging-simple();
}

//
// toggles widgets by breakpoint (requires JS for onclick toggle)
//
@mixin widget-toggle($breakpoint: $widget-toggle) {

	@include media-breakpoint-down($breakpoint) {

		.widget-body {

			& > * {
				display: none;
			}

			.widget_label {
				display: block;
				position: relative;
//				margin: $grid-gutter-width / 2 0;
				cursor: pointer;
			}
		}

		&.active {

			.widget-body > * {
				display: block;
			}
		}
	}

	@include media-breakpoint-up(breakpoint-next($breakpoint, $grid-breakpoints)) {

		.widget-body {

			& > .widget_label + * {
				display: block;
			}

			.widget_label {
				pointer-events: none;
			}
		}
	}
}

.widget-toggle {
	@include widget-toggle();
}

.widget-noicon {
	& > .widget_label::before {
		display: none;
	}
}

.widget-nolabel {
	& > .widget_label {
		display: none;
	}
}

@each $key, $val in $grid-breakpoints {

	@include media-breakpoint-only($key) {

		.widget-nolabel-#{$key} {

			& > .widget_label {
				display: none;
			}
		}
	}
}

.fullvw {
	flex: 1 100vw;
	margin-left: calc(50% - 50vw);
	margin-right: calc(50% - 50vw);
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.blink {
	animation: blinker 2s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0.1;
	}
}

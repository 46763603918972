/*
	.bg-vgradient-{$theme-colors}
	.bg-{$extra-bg-colors}
	.bg-fx-{$extra-bg-colors}
	.bg-fl-{$extra-bg-colors}
	.bg-fr-{$extra-bg-colors}
	.bg-fe-{$extra-bg-colors}
*/

@each $color, $value in $theme-colors {

	.bg-vgradient-#{$color} {
		@include gradient-y(lighten($value, 15%), $value);
	}
}

@each $color, $value in $extra-bg-colors {

	.bg-#{$color} {
		background: $value;
	}

	.bg-fx-#{$color} {
		background: linear-gradient(to right, rgba(red($value),green($value),blue($value),0), rgba(red($value),green($value),blue($value),alpha($value)) 30%, rgba(red($value),green($value),blue($value),alpha($value)) 70%, rgba(red($value),green($value),blue($value),0));
	}

	.bg-fl-#{$color} {
		background: linear-gradient(to right, rgba(red($value),green($value),blue($value),0), rgba(red($value),green($value),blue($value),alpha($value)) 30%, rgba(red($value),green($value),blue($value),alpha($value)));
	}

	.bg-fr-#{$color} {
		background: linear-gradient(to right, rgba(red($value),green($value),blue($value),alpha($value)) 70%, rgba(red($value),green($value),blue($value),0));
	}

	.bg-fe-#{$color} {
		background: radial-gradient(ellipse closest-side, transparent 20%, $value 100%);
	}
}

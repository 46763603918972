//HDROPTNS//{"repo":"self","bookmarks":[11,64,78,121,122,191,271,327,426,816,906,944,1064,1151,1168,1284,1327,1377,1401,1427,1444,1459,1469,1489,1506,1524,1535,1581,1625,1642,1659,1675]}//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*!
Theme Name: SELF
Theme URI: http://www.apisoft.pl/
Author: APISOFT
Author URI: https://www.apisoft.pl/
Description: All rights reserved / Wszelkie prawa zastrzeżone
License: All rights reserved / Wszelkie prawa zastrzeżone
Version: 1.0
*/
//
// Bootstrap config
//
$white:  #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$grays: (
	100: $gray-100,
	200: $gray-200,
	300: $gray-300,
	400: $gray-400,
	500: $gray-500,
	600: $gray-600,
	700: $gray-700,
	800: $gray-800,
	900: $gray-900
);

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: (
	blue: $blue,
	indigo: $indigo,
	purple: $purple,
	pink: $pink,
	red: $red,
	orange: $orange,
	yellow: $yellow,
	green: $green,
	teal: $teal,
	cyan: $cyan,
	white: $white,
	gray: $gray-600,
	gray-dark: $gray-800
);

$theme-colors: (
	primary: #12a554,
	secondary: $gray-400,
//	white-10: rgba(255, 255, 255, 0.1), // +4 KB of output file
//	black-10: rgba(0, 0, 0, 0.1), // +4 KB of output file
	default: $gray-300,
	success: $green,
	info: $cyan,
	warning: $yellow,
	danger: $red,
	light: $gray-100,
	dark: $gray-800,
);

$extra-bg-colors: (
	white-20: rgba(255, 255, 255, 0.2),
	white-40: rgba(255, 255, 255, 0.4),
	black-20: rgba(0, 0, 0, 0.2),
	black-40: rgba(0, 0, 0, 0.4),
	gray: $gray-200,
);

//$link-color:                              theme-color("primary");
//$link-decoration:                         none;
//$link-hover-color:                        darken($link-color, 15%);
$link-hover-decoration:                   none;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
//$emphasized-link-hover-darken-percentage: 15%;

$enable-rounded:            true;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;

$spacer: 24px;
$spacers: (
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3)
);

$sizes: (
	20: 20%,
	25: 25%,
	33: 33.33333%,
	50: 50%,
	66: 66.66666%,
	75: 75%,
	100: 100%
);

$body-bg:       $white;
$body-color:    $gray-700;

$grid-columns: 24;
$grid-gutter-width: $spacer;

$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem;
$font-size-sm:   .875rem;

$font-weight-normal: normal;
$font-weight-bold: bold;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: 2.25rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

$headings-margin-bottom: (1rem / 2);
$headings-font-family:   inherit;
$headings-font-weight:   500;
$headings-line-height:   1.1;
$headings-color:         inherit;

//
// Hover.css config
//

$nameSpace: 'hvr';

$fastDuration: .1s;
$mediumDuration: .3s;
$slowDuration: .5s;

// Colors
$primaryColor: map-get($theme-colors, primary);
$secondaryColor: map-get($theme-colors, secondary);
$highlightColor: #ccc;
$activeColor: #2098D1;
$shadowColor: rgba(0, 0, 0, .6);

$tipWidth: 10px;
$tipHeight: 10px;
$tipColor: $primaryColor;

$spaceBetweenTextAndArrows: 2.2em;

$curlWidth: 25px;
$curlHeight: 25px;

$revealAreaColor: white;
$curlLineColor: #aaa;
$curlTransitionColor: #ccc;
$curlLastColor: white;

$webkit: false;
$moz: false;
$ms: false;
$o: false;

//
// Extra config
//

$column-wrap: 0; // 10px;

$column-left-width: (
//	sm: 240px,
	md: 240px,
	lg: 240px,
	xl: 240px
);

$column-right-width: (
//	sm: 0,
	md: 0,
	lg: 0,
	xl: 300px
);

$menu-levels: 3;

$border-sizes: (
	1: 1px,
	2: 2px,
	3: 3px,
	5: 5px
);

$shadow-color: rgba(0, 0, 0, 0.4);

$box-shadow-sm: 0 0 .25rem rgba($black, .075);
$box-shadow: 0 0 1rem rgba($black, .15);
$box-shadow-lg: 0 0 3rem rgba($black, .175);

$text-shadow-black-sm: 0 0 .5rem rgba($black, .5);
$text-shadow-black: 0 0 .5rem rgba($black, .8);
$text-shadow-black-lg: 0 0 .5rem rgba($black, 1);
$text-stroke-black: 0 0 1px $black;

$text-shadow-white-sm: 0 0 .5rem rgba($white, .5);
$text-shadow-white: 0 0 .5rem rgba($white, .8);
$text-shadow-white-lg: 0 0 .5rem rgba($white, 1);
$text-stroke-white: 0 0 1px $white;

$widget-toggle: sm;

$icon-width: 500px; //100%; // 300px | 100% | 0

$icon-height: if(unit($icon-width) == px, $icon-width / 4 * 3, false); // 200px | false

$icon-edging: true; // true | false


$items-icon-width: 300px; // 300px | 100% | 0

$items-icon-height: if(unit($items-icon-width) == px, $items-icon-width / 4 * 3, false); // 200px | false

$items-icon-edging: true; // true | false


$items-edging: 0; // 0 | $grid-gutter-width | 10px

$items-justify-content: flex-start; // flex-start | flex-end | center | space-between | space-around | space-evenly

$items-per-row: (
	sm: 1,
	md: 1,
	lg: 1,
	xl: 1
);

$gallery-justify-content: flex-start; // flex-start | flex-end | center | space-between | space-around | space-evenly

$gallery-max-height: 188px;

$gallery-icon-edging: true; // true | false

$gallery-multicolumn-breakpoint: lg;

//
// Google Fonts config
//
// Preview:
// https://fonts.google.com/selection?selection.family=Alegreya+Sans:400,400i,700,700i|Allura|Bowlby+One+SC|Caveat+Brush|Coda|Courgette|Didact+Gothic|Dynalight|Great+Vibes|Italianno|Josefin+Sans:400,400i,700,700i|Jura:400,700|Kalam:400,700|Kaushan+Script|Lato:400,400i,700,700i|Limelight|Lobster|Marck+Script|Monda:400,700|Montserrat:400,400i,700,700i|Noto+Sans:400,400i,700,700i|Nunito:400,400i,700,700i|Open+Sans:400,400i,700,700i|Oswald:400,700|PT+Mono|PT+Sans+Narrow|PT+Sans:400,400i,700,700i|PT+Serif:400,400i,700,700i|Parisienne|Playfair+Display+SC:400,400i,700,700i|Playfair+Display:400,400i,700,700i|Poiret+One|Poppins:400,400i,700,700i|Prosto+One|Racing+Sans+One|Roboto+Mono:400,400i,700,700i|Roboto:400,400i,700,700i|Romanesco|Russo+One|Sacramento|Sarina|Teko:300,400,700|Tinos:400,400i,700,700i|Titan+One|Titillium+Web:400,400i,700,700i|Ubuntu:400,400i,700,700i|Varela
//

$google-fonts: (
//	'Alegreya Sans': 'Alegreya+Sans:400,400i,700,700i',
//	'Allura': 'Allura',
//	'Bowlby One SC': 'Bowlby+One+SC',
//	'Caveat Brush': 'Caveat+Brush',
//	'Coda': 'Coda',
//	'Courgette': 'Courgette',
//	'Didact Gothic': 'Didact+Gothic',
//	'Dynalight': 'Dynalight',
//	'Great Vibes': 'Great+Vibes',
//	'Italianno': 'Italianno',
//	'Josefin Sans': 'Josefin+Sans:400,400i,700,700i',
//	'Jura': 'Jura:400,700',
//	'Kalam': 'Kalam:400,700',
//	'Kaushan Script': 'Kaushan+Script',
//	'Lato': 'Lato:400,400i,700,700i',
//	'Limelight': 'Limelight',
//	'Lobster': 'Lobster',
//	'Marck Script': 'Marck+Script',
//	'Monda': 'Monda:400,700',
//	'Montserrat': 'Montserrat:400,400i,700,700i',
//	'Noto Sans': 'Noto+Sans:400,400i,700,700i',
//	'Nunito': 'Nunito:400,400i,700,700i',
	'Open Sans': 'Open+Sans:400,400i,700,700i',
//	'Oswald': 'Oswald:400,700',
//	'PT Mono': 'PT+Mono',
//	'PT Sans': 'PT+Sans:400,400i,700,700i',
//	'PT Serif': 'PT+Serif:400,400i,700,700i',
//	'Parisienne': 'Parisienne',
//	'Playfair Display SC': 'Playfair+Display+SC:400,400i,700,700i',
//	'Playfair Display': 'Playfair+Display:400,400i,700,700i',
//	'Poiret One': 'Poiret+One',
//	'Poppins': 'Poppins:400,400i,700,700i',
//	'Prosto One': 'Prosto+One',
//	'Racing Sans One': 'Racing+Sans+One',
//	'Roboto Mono': 'Roboto+Mono:400,400i,700,700i',
//	'Roboto': 'Roboto:400,400i,700,700i',
//	'Romanesco': 'Romanesco',
//	'Russo One': 'Russo+One',
//	'Sacramento': 'Sacramento',
//	'Sarina': 'Sarina',
	'Teko': 'Teko:400,700',
//	'Tinos': 'Tinos:400,400i,700,700i',
//	'Titan One': 'Titan+One',
//	'Titillium Web': 'Titillium+Web:400,400i,700,700i',
//	'Ubuntu': 'Ubuntu:400,400i,700,700i',
//	'Varela': 'Varela'
);

//
// Font Awesome config
//

$fa-font-path:      'fonts';
$fa-font-size-base: 16px !default;
$fa-border-color:   #eee !default;

$fa-icons: (
	fa-twitter-square: "\f081",
	fa-twitter: "\f099",
	fa-facebook-square: "\f082",
	fa-facebook: "\f09a",
	fa-facebook-f: "\f39e",
	fa-instagram: "\f16d",
//	fa-linkedin-square: "\f08c",
	fa-linkedin: "\f0e1",
//	fa-github-square: "\f092",
//	fa-github: "\f09b",
//	fa-pinterest-square: "\f0d3",
//	fa-pinterest: "\f0d2",
//	fa-pinterest-p: "\f231",
	fa-google-plus-square: "\f0d4",
	fa-google-plus-g: "\f0d5",
//	fa-html5: "\f13b",
//	fa-css3: "\f13c",
//	fa-vimeo-square: "\f194",
	fa-youtube-square: "\f166",
	fa-youtube: "\f167",
	fa-youtube-play: "\f16a",
//	fa-dropbox: "\f16b",
//	fa-git-square: "\f1d2",
//	fa-git: "\f1d3",
	fa-globe: "\f0ac",

	fa-bars: "\f0c9",
	fa-bookmark: "\f02e",
	fa-bookmark-o: "\f097",
	fa-check: "\f00c",
	fa-chart-line: "\f201",
	fa-code: "\f121",
	fa-comments: "\f086",
	fa-database: "\f1c0",
	fa-download: "\f019",
	fa-envelope: "\f0e0",
	fa-envelope-o: "\f003",
	fa-fax: "\f1ac",
	fa-film: "\f008",
	fa-gift: "\f06b",
	fa-handshake: "\f2b5",
	fa-home: "\f015",
	fa-laptop: "\f109",
	fa-lock: "\f023",
	fa-map: "\f279",
	fa-map-marker: "\f041",
	fa-map-o: "\f278",
	fa-mobile-alt: "\f3cd",
	fa-minus-circle: "\f056",
	fa-phone: "\f095",
	fa-phone-square: "\f098",
	fa-piggy-bank: "\f4d3",
	fa-plus-circle: "\f055",
	fa-power-off: "\f011",
	fa-print: "\f02f",
	fa-search: "\f002",
	fa-search-plus: "\f00e",
	fa-search-minus: "\f010",
	fa-server: "\f233",
	fa-sign-in: "\f090",
	fa-shopping-cart: "\f07a",
	fa-sign-out: "\f08b",
	fa-tag: "\f02b",
	fa-tags: "\f02c",
	fa-thumbs-down: "\f165",
	fa-thumbs-up: "\f164",
	fa-times: "\f00d",
	fa-unlock: "\f09c",
	fa-unlock-alt: "\f13e",
	fa-upload: "\f093",
	fa-user: "\f007",
	fa-users: "\f0c0",

	fa-ellipsis-h: "\f141",
	fa-ellipsis-v: "\f142",
	fa-angle-double-down: "\f103",
	fa-angle-double-left: "\f100",
	fa-angle-double-right: "\f101",
	fa-angle-double-up: "\f102",
	fa-angle-down: "\f107",
	fa-angle-left: "\f104",
	fa-angle-right: "\f105",
	fa-angle-up: "\f106",
	fa-thumbs-o-down: "\f088",
	fa-thumbs-o-up: "\f087",

	fa-venus: "\f221",
	fa-mars: "\f222"
);

//
// Imports
//

@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
//@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
//@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
//@import "bootstrap/custom-forms";
//@import "bootstrap/nav";
//@import "bootstrap/navbar";
//@import "bootstrap/card";
//@import "bootstrap/breadcrumb";
//@import "bootstrap/pagination";
@import "bootstrap/badge";
//@import "bootstrap/jumbotron";
@import "bootstrap/alert";
//@import "bootstrap/progress";
//@import "bootstrap/media";
//@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/toasts";
@import "bootstrap/modal";
//@import "bootstrap/tooltip";
//@import "bootstrap/popover";
//@import "bootstrap/carousel";
//@import "bootstrap/spinners";
@import "bootstrap/utilities";
@import "bootstrap/print";

@import "font-awesome/variables";
@import "font-awesome/mixins";
@import "font-awesome/core";
@import "font-awesome/larger";
//@import "font-awesome/fixed-width";
//@import "font-awesome/list";
@import "font-awesome/bordered-pulled";
//@import "font-awesome/animated";
@import "font-awesome/rotated-flipped";
//@import "font-awesome/stacked";
//@import "font-awesome/icons";
//@import "font-awesome/screen-reader";

@import "hover-css/options";
@import "hover-css/mixins";
@import "hover-css/hacks";

//@import "hover-css/effects/2d-transitions/grow";
//@import "hover-css/effects/2d-transitions/shrink";
//@import "hover-css/effects/2d-transitions/pulse";
//@import "hover-css/effects/2d-transitions/pulse-grow";
//@import "hover-css/effects/2d-transitions/pulse-shrink";
//@import "hover-css/effects/2d-transitions/push";
@import "hover-css/effects/2d-transitions/pop";
@import "hover-css/effects/2d-transitions/bounce-in";
//@import "hover-css/effects/2d-transitions/bounce-out";
//@import "hover-css/effects/2d-transitions/rotate";
//@import "hover-css/effects/2d-transitions/grow-rotate";
@import "hover-css/effects/2d-transitions/float";
@import "hover-css/effects/2d-transitions/sink";
//@import "hover-css/effects/2d-transitions/bob";
//@import "hover-css/effects/2d-transitions/hang";
//@import "hover-css/effects/2d-transitions/skew";
//@import "hover-css/effects/2d-transitions/skew-forward";
//@import "hover-css/effects/2d-transitions/skew-backward";
@import "hover-css/effects/2d-transitions/wobble-vertical";
@import "hover-css/effects/2d-transitions/wobble-horizontal";
//@import "hover-css/effects/2d-transitions/wobble-to-bottom-right";
//@import "hover-css/effects/2d-transitions/wobble-to-top-right";
//@import "hover-css/effects/2d-transitions/wobble-top";
//@import "hover-css/effects/2d-transitions/wobble-bottom";
//@import "hover-css/effects/2d-transitions/wobble-skew";
//@import "hover-css/effects/2d-transitions/buzz";
@import "hover-css/effects/2d-transitions/buzz-out";
//@import "hover-css/effects/2d-transitions/forward";
//@import "hover-css/effects/2d-transitions/backward";
@import "hover-css/effects/background-transitions/fade";
//@import "hover-css/effects/background-transitions/back-pulse";
@import "hover-css/effects/background-transitions/sweep-to-right";
@import "hover-css/effects/background-transitions/sweep-to-left";
@import "hover-css/effects/background-transitions/sweep-to-bottom";
@import "hover-css/effects/background-transitions/sweep-to-top";
@import "hover-css/effects/background-transitions/bounce-to-right";
@import "hover-css/effects/background-transitions/bounce-to-left";
@import "hover-css/effects/background-transitions/bounce-to-bottom";
@import "hover-css/effects/background-transitions/bounce-to-top";
@import "hover-css/effects/background-transitions/radial-out";
@import "hover-css/effects/background-transitions/radial-in";
@import "hover-css/effects/background-transitions/rectangle-in";
@import "hover-css/effects/background-transitions/rectangle-out";
@import "hover-css/effects/background-transitions/shutter-in-horizontal";
@import "hover-css/effects/background-transitions/shutter-out-horizontal";
@import "hover-css/effects/background-transitions/shutter-in-vertical";
@import "hover-css/effects/background-transitions/shutter-out-vertical";
//@import "hover-css/effects/border-transitions/border-fade";
//@import "hover-css/effects/border-transitions/hollow";
//@import "hover-css/effects/border-transitions/trim";
@import "hover-css/effects/border-transitions/ripple-out";
//@import "hover-css/effects/border-transitions/ripple-in";
//@import "hover-css/effects/border-transitions/outline-out";
//@import "hover-css/effects/border-transitions/outline-in";
//@import "hover-css/effects/border-transitions/round-corners";
@import "hover-css/effects/border-transitions/underline-from-left";
@import "hover-css/effects/border-transitions/underline-from-center";
@import "hover-css/effects/border-transitions/underline-from-right";
@import "hover-css/effects/border-transitions/overline-from-left";
@import "hover-css/effects/border-transitions/overline-from-center";
@import "hover-css/effects/border-transitions/overline-from-right";
//@import "hover-css/effects/border-transitions/reveal";
//@import "hover-css/effects/border-transitions/underline-reveal";
//@import "hover-css/effects/border-transitions/overline-reveal";
//@import "hover-css/effects/shadow-and-glow-transitions/glow";
//@import "hover-css/effects/shadow-and-glow-transitions/shadow";
//@import "hover-css/effects/shadow-and-glow-transitions/grow-shadow";
//@import "hover-css/effects/shadow-and-glow-transitions/box-shadow-outset";
//@import "hover-css/effects/shadow-and-glow-transitions/box-shadow-inset";
//@import "hover-css/effects/shadow-and-glow-transitions/float-shadow";
//@import "hover-css/effects/shadow-and-glow-transitions/shadow-radial";
//@import "hover-css/effects/speech-bubbles/bubble-top";
//@import "hover-css/effects/speech-bubbles/bubble-right";
//@import "hover-css/effects/speech-bubbles/bubble-bottom";
//@import "hover-css/effects/speech-bubbles/bubble-left";
//@import "hover-css/effects/speech-bubbles/bubble-float-top";
//@import "hover-css/effects/speech-bubbles/bubble-float-right";
//@import "hover-css/effects/speech-bubbles/bubble-float-bottom";
//@import "hover-css/effects/speech-bubbles/bubble-float-left";
//@import "hover-css/effects/icons/icon-back";
//@import "hover-css/effects/icons/icon-forward";
//@import "hover-css/effects/icons/icon-down";
//@import "hover-css/effects/icons/icon-up";
//@import "hover-css/effects/icons/icon-spin";
//@import "hover-css/effects/icons/icon-drop";
//@import "hover-css/effects/icons/icon-fade";
//@import "hover-css/effects/icons/icon-float-away";
//@import "hover-css/effects/icons/icon-sink-away";
//@import "hover-css/effects/icons/icon-grow";
//@import "hover-css/effects/icons/icon-shrink";
//@import "hover-css/effects/icons/icon-pulse";
//@import "hover-css/effects/icons/icon-pulse-grow";
//@import "hover-css/effects/icons/icon-pulse-shrink";
//@import "hover-css/effects/icons/icon-push";
//@import "hover-css/effects/icons/icon-pop";
//@import "hover-css/effects/icons/icon-bounce";
//@import "hover-css/effects/icons/icon-rotate";
//@import "hover-css/effects/icons/icon-grow-rotate";
//@import "hover-css/effects/icons/icon-float";
//@import "hover-css/effects/icons/icon-sink";
//@import "hover-css/effects/icons/icon-bob";
//@import "hover-css/effects/icons/icon-hang";
//@import "hover-css/effects/icons/icon-wobble-horizontal";
//@import "hover-css/effects/icons/icon-wobble-vertical";
//@import "hover-css/effects/icons/icon-buzz";
//@import "hover-css/effects/icons/icon-buzz-out";
//@import "hover-css/effects/curls/curl-top-left";
//@import "hover-css/effects/curls/curl-top-right";
//@import "hover-css/effects/curls/curl-bottom-right";
//@import "hover-css/effects/curls/curl-bottom-left";

@import "animate-css/_base";
@import "animate-css/zooming_exits/zoomOutUp";
@import "animate-css/zooming_exits/zoomOutRight";
@import "animate-css/zooming_exits/zoomOutLeft";
@import "animate-css/zooming_exits/zoomOutDown";
@import "animate-css/zooming_exits/zoomOut";
@import "animate-css/zooming_entrances/zoomInUp";
@import "animate-css/zooming_entrances/zoomInRight";
@import "animate-css/zooming_entrances/zoomInLeft";
@import "animate-css/zooming_entrances/zoomInDown";
@import "animate-css/zooming_entrances/zoomIn";
@import "animate-css/specials/rollOut";
@import "animate-css/specials/rollIn";
@import "animate-css/specials/jackInTheBox";
@import "animate-css/specials/hinge";
@import "animate-css/sliding_exits/slideOutUp";
@import "animate-css/sliding_exits/slideOutRight";
@import "animate-css/sliding_exits/slideOutLeft";
@import "animate-css/sliding_exits/slideOutDown";
@import "animate-css/sliding_entrances/slideInUp";
@import "animate-css/sliding_entrances/slideInRight";
@import "animate-css/sliding_entrances/slideInLeft";
@import "animate-css/sliding_entrances/slideInDown";
@import "animate-css/rotating_exits/rotateOutUpRight";
@import "animate-css/rotating_exits/rotateOutUpLeft";
@import "animate-css/rotating_exits/rotateOutDownRight";
@import "animate-css/rotating_exits/rotateOutDownLeft";
@import "animate-css/rotating_exits/rotateOut";
@import "animate-css/rotating_entrances/rotateInUpRight";
@import "animate-css/rotating_entrances/rotateInUpLeft";
@import "animate-css/rotating_entrances/rotateInDownRight";
@import "animate-css/rotating_entrances/rotateInDownLeft";
@import "animate-css/rotating_entrances/rotateIn";
@import "animate-css/lightspeed/lightSpeedOut";
@import "animate-css/lightspeed/lightSpeedIn";
@import "animate-css/flippers/flipOutY";
@import "animate-css/flippers/flipOutX";
@import "animate-css/flippers/flipInY";
@import "animate-css/flippers/flipInX";
@import "animate-css/flippers/flip";
@import "animate-css/fading_exits/fadeOutUpBig";
@import "animate-css/fading_exits/fadeOutUp";
@import "animate-css/fading_exits/fadeOutRightBig";
@import "animate-css/fading_exits/fadeOutRight";
@import "animate-css/fading_exits/fadeOutLeftBig";
@import "animate-css/fading_exits/fadeOutLeft";
@import "animate-css/fading_exits/fadeOutDownBig";
@import "animate-css/fading_exits/fadeOutDown";
@import "animate-css/fading_exits/fadeOut";
@import "animate-css/fading_entrances/fadeInUpBig";
@import "animate-css/fading_entrances/fadeInUp";
@import "animate-css/fading_entrances/fadeInRightBig";
@import "animate-css/fading_entrances/fadeInRight";
@import "animate-css/fading_entrances/fadeInLeftBig";
@import "animate-css/fading_entrances/fadeInLeft";
@import "animate-css/fading_entrances/fadeInDownBig";
@import "animate-css/fading_entrances/fadeInDown";
@import "animate-css/fading_entrances/fadeIn";
@import "animate-css/bouncing_exits/bounceOutUp";
@import "animate-css/bouncing_exits/bounceOutRight";
@import "animate-css/bouncing_exits/bounceOutLeft";
@import "animate-css/bouncing_exits/bounceOutDown";
@import "animate-css/bouncing_exits/bounceOut";
@import "animate-css/bouncing_entrances/bounceInUp";
@import "animate-css/bouncing_entrances/bounceInRight";
@import "animate-css/bouncing_entrances/bounceInLeft";
@import "animate-css/bouncing_entrances/bounceInDown";
@import "animate-css/bouncing_entrances/bounceIn";
@import "animate-css/attention_seekers/wobble";
@import "animate-css/attention_seekers/tada";
@import "animate-css/attention_seekers/swing";
@import "animate-css/attention_seekers/shake";
@import "animate-css/attention_seekers/rubberBand";
@import "animate-css/attention_seekers/pulse";
@import "animate-css/attention_seekers/jello";
@import "animate-css/attention_seekers/heartBeat";
@import "animate-css/attention_seekers/headShake";
@import "animate-css/attention_seekers/flash";
@import "animate-css/attention_seekers/bounce";

@import "extra/functions";
@import "extra/fonts";
@import "extra/backgrounds";
@import "extra/lists";
@import "extra/buttons";
@import "extra/borders";
@import "extra/shadows";
@import "extra/menu";
@import "extra/layout";
@import "extra/positions";
@import "extra/snippets";
@import "extra/utils";
@import "extra/widgets";
@import "extra/wordpress";

// Import fonts
$fonts-url: 'https://fonts.googleapis.com/css?family=';

@each $font-name, $font-url in $google-fonts {

	$fonts-url: $fonts-url + $font-url + '|';
}
$fonts-url: str-slice($fonts-url, 0, -2) + '&subset=latin-ext';
@import url($fonts-url);

:root {

//	@each $color, $value in $colors {
//	--#{$color}: #{$value};
//	}

	@each $color, $value in $theme-colors {
	--#{$color}: #{$value};
	}

	@each $color, $value in $extra-bg-colors {
	--#{$color}: #{$value};
	}

	--font-family-sans-serif: #{inspect($font-family-sans-serif)};
	--font-family-monospace: #{inspect($font-family-monospace)};
	--font-family-base: #{inspect($font-family-base)};

	@each $font-name, $font-url in $google-fonts {

		$slug: '';

		@for $i from 1 through str-length($font-name)  {

			$char: str-slice($font-name, $i, $i);

			@if($char == ' ') {

				$char: '-';
			}

			$slug: $slug + to-lower-case($char);
		}

		--font-family-#{$slug}: "#{$font-name}";
	}
}

@each $font-name, $font-url in $google-fonts {

	$slug: '';

	@for $i from 1 through str-length($font-name)  {

		$char: str-slice($font-name, $i, $i);

		@if($char == ' ') {

			$char: '-';
		}

		$slug: $slug + to-lower-case($char);
	}

	.font-family-#{$slug} {
		font-family: quote(#{$font-name});
	}
}

@font-face {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: 400;
	src: url('#{$fa-font-path}/fa-regular-400.eot');
	src: url('#{$fa-font-path}/fa-regular-400.eot?#iefix') format('embedded-opentype'),
	url('#{$fa-font-path}/fa-regular-400.woff2') format('woff2'),
	url('#{$fa-font-path}/fa-regular-400.woff') format('woff'),
	url('#{$fa-font-path}/fa-regular-400.ttf') format('truetype'),
	url('#{$fa-font-path}/fa-regular-400.svg#fontawesome') format('svg');
}

.far {
	font-family: 'FontAwesome';
	font-weight: 400;
}

@font-face {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: 900;
	src: url('#{$fa-font-path}/fa-solid-900.eot');
	src: url('#{$fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'),
	url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
	url('#{$fa-font-path}/fa-solid-900.woff') format('woff'),
	url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype'),
	url('#{$fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}

.fa,
.fas {
	font-family: 'FontAwesome';
	font-weight: 900;
}

@font-face {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: normal;
	src: url('#{$fa-font-path}/fa-brands-400.eot');
	src: url('#{$fa-font-path}/fa-brands-400.eot?#iefix') format('embedded-opentype'),
	url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2'),
	url('#{$fa-font-path}/fa-brands-400.woff') format('woff'),
	url('#{$fa-font-path}/fa-brands-400.ttf') format('truetype'),
	url('#{$fa-font-path}/fa-brands-400.svg#fontawesome') format('svg');
}

.fab {
	font-family: 'FontAwesome';
}

.#{$fa-css-prefix}-fw {
  text-align: center;
  width: 1.5em;
}

//
// Tags
//

html {
//	overflow-y: scroll;
}

body {
//	border-top: 4px solid $brand-primary;
//	@include gradient-vertical(#ddd, rgba(255,255,255,0), 0%, 200px);

//	background-image: url('images/bg.jpg');
//	background-position: center top;
//	background-repeat: no-repeat; // repeat-x;
//	background-size: cover; // contain;
	overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
	margin-top: .5em;
	margin-bottom: .5em;
}

p,ul {
	margin-top: .25em;
	margin-bottom: .25em;
}

input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
textarea,
select {
	display: block;
	width: 100%;
	padding: $input-btn-padding-y $input-btn-padding-x;
	font-size: $font-size-base;
	line-height: $input-btn-line-height;
	color: $input-color;
	background-color: $input-bg;
	background-image: none;
	background-clip: padding-box;
	border: $input-btn-border-width solid $input-border-color;

	@if $enable-rounded {
		border-radius: $input-border-radius;
	} @else {
		border-radius: 0;
	}

	@include box-shadow($input-box-shadow);
	@include transition($input-transition);

	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}

	@include form-control-focus();

	&::placeholder {
		color: $input-placeholder-color;
		opacity: 1;
	}

	&:disabled,
	&[readonly] {
		background-color: $input-disabled-bg;
		opacity: 1;
	}
}

input[type='button'],
input[type='reset'],
input[type='submit'],
button {
	@include btn();
}

//
// Classes
//

@each $fa-icon, $fa-val in $fa-icons {

	.#{$fa-icon}:before { content: $fa-val; }
}


.no-image {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	overflow: hidden;
	padding: 20px 0;
	text-align: center;
	font-size: 56px !important;
	color: #fff;
	background-color: $gray-100;
	text-shadow: 0 0 2px darken($gray-500, 10%);
	text-decoration: none;
	@include fas("\f030", 0);
	line-height: 60px;
}

.wp-caption {
	padding: 4px;
	border-width: 1px;
	border-radius: $border-radius;
	max-width: 100%;
}

.wp-caption-text {
	padding: 4px 4px 0 !important;
}

//
// Widgets
//

.widget-grid {

	padding: $grid-gutter-width / 2;

	.widget_label {
		@include fas("\f0da", 0, inherit, 1.2em);

		font-size: $h5-font-size;
		font-weight: $font-weight-bold;
	}

	img {
		@extend .img-fluid;
		margin-left: auto !important;
		margin-right: auto !important;
	}
}

.widget-body {
	height: 100%;

}

.widget-body[class^='bg-'],
.widget-body[class*=' bg-'],
.widget-body[class^='border-'],
.widget-body[class*=' border-'],
.widget-body.border {
	padding: $grid-gutter-width;
}

// reset padding for menu-horizontal
.widget-body[class^='menu-horizontal'],
.widget-body[class*=' menu-horizontal'] {
	padding: 0;
}

.widget-body .gallery {
	margin-top: -10px;
	margin-bottom: -10px;
}

.widget_media_image {

	.alignnone {
		margin: 0;
	}

	img {
		margin-top: 0;
		margin-bottom: 0;
		display: block;
	}
}

.widget_search {

//	$input-border-color: map_get($theme-colors, 'primary');

	.screen-reader-text {
		display: none;
	}

	form {
		@include fas(map_get($fa-icons, 'fa-search'), 0, map_get($theme-colors, 'primary'));
		padding-right: 40px;
		position: relative;

		&:before {
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			height: 100%;
			padding: $input-btn-padding-x $input-btn-padding-y;
			border-radius: 0 $input-border-radius $input-border-radius 0;
			border: 1px solid $input-border-color;
			border-left-width: 0;
		}
	}

	input[type=text] {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right-width: 0;
		border-color: $input-border-color;
	}

	input[type=text]:hover,input[type=text]:focus {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right-width: 0;
		border-color: $input-border-color;
	}

	input[type=submit] {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
		position: absolute;
		top: 0;
		right: 0;
		width: 40px;
		opacity: 0;
	}
}

.widget_tag_cloud {

	.widget_label {
		@include fas("\f02c", 0);
	}

	.tagcloud {
		text-align: center;
	}
}

.widget-info {

	.widget_label {
		@include fas("\f129", 0);
	}
}

.widget_selfseobreadcrumbswidget {
	width: 100%;
}

.wpcf7-form p {
	margin-bottom: 1rem;
}

.wpcf7-form span.wpcf7-not-valid-tip {
	@extend .small;
	@extend .text-danger;
}

.wpcf7-form .wpcf7-not-valid {
	@extend .is-invalid;
}

.wpcf7-form div.wpcf7-response-output {
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	@extend .alert;
}

.wpcf7-form div.wpcf7-mail-sent-ok {
	@extend .alert-success;
}

.wpcf7-form div.wpcf7-mail-sent-ng,
.wpcf7-form div.wpcf7-aborted {
	@extend .alert-danger;
}

.wpcf7-form div.wpcf7-spam-blocked {
	@extend .alert-danger;
}

.wpcf7-form div.wpcf7-validation-errors,
.wpcf7-form div.wpcf7-acceptance-missing {
	@extend .alert-danger;
}

//
// Gallery
//

.gallery {
	@include make-row();
	justify-content: $gallery-justify-content;

	.gallery-item {
		margin: 0;
		width: 100%;
		padding: $grid-gutter-width / 2;
	}

	.gallery-icon {
	}

	.gallery-icon img {
		@if $gallery-icon-edging {
			@extend .img-thumbnail;
		}

		object-fit: cover;
		@if $gallery-max-height {
			max-height: $gallery-max-height;
		}

		margin: 0 auto;
		display: block;
	}

	.gallery-caption {
		display: block;
		font-size: $font-size-sm;
		padding-top: $grid-gutter-width / 2;
		text-align: center;
	}
}

@include media-breakpoint-up($gallery-multicolumn-breakpoint) {

	@for $i from 2 through 9 {

		.gallery-columns-#{$i} .gallery-item {
			max-width: 100% / $i;
		}
	}
}

//
// Menu
//

li.menu-item-has-children > a > span:after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid;
	border-right: .3em solid transparent;
	border-bottom: 0;
	border-left: .3em solid transparent;
}

//
// Menu Snippets
//

// default style for current elements
.menu li.current-menu-item > a {
//	background-color: map-get($theme-colors, primary);
//	color: map-get($theme-colors, primary);
}

/**
.menu li:hover > a {
	background-color: map-get($theme-colors, primary);
	color: $white; //map-get($theme-colors, primary);
}
/**/

/**
.menu-cube-down div > ul {

	& > li:not(.current-menu-item) > a {

		@include cube-down(map-get($theme-colors, primary), $white);
	}
}
/**/

/**
.menu-sweep-up-out div > ul {

	& li:not(.current-menu-item) > a {

		@include sweep-up-out(map-get($theme-colors, primary), $white);
	}
}
/**/

/**
.menu-fade div > ul {

	& > li:not(.current-menu-item) > a {

		@include fade();

		&:hover,
		&:focus,
		&:active {
			background-color: map-get($theme-colors, primary);
			color: $white;
		}
	}
}
/**/

/**
.menu-bounce-to-bottom div > ul {

	& > li:not(.current-menu-item) > a {

		@include bounce-to-bottom();

		&:before {
			background-color: map-get($theme-colors, primary);
			color: $white;
		}
	}
}
/**/

/**
.menu-underline-from-left div > ul > li > a {

	@include underline-from-left();

	background-color: transparent;
	color: inherit;

	&:before {
		background: map-get($theme-colors, primary);
		height: 3px;
	}

	&.current-menu-item:before {
		content: "";
		position: absolute;
		z-index: -1;
		left: 0%;
		right: 0%;
		bottom: 0;
		background: map-get($theme-colors, primary);
		height: 3px;
	}
}
/**/

/**
.menu-underline-from-center div > ul > li > a {

	@include underline-from-center();

	background-color: transparent;
	color: inherit;

	&:before {
		background: map-get($theme-colors, primary);
		height: 3px;
	}

	&.current-menu-item:before {
		content: "";
		position: absolute;
		z-index: -1;
		left: 0%;
		right: 0%;
		bottom: 0;
		background: map-get($theme-colors, primary);
		height: 3px;
	}
}
/**/

/** Animate.css example { **
.menu-bounce div > ul > li > a:hover span {
	animation: bounceIn 1s;
}
/** } **/
//
// Ids
//

//
// Layout
//

@if mixin-exists(layout) {

	@include layout();
}

#body_top {
}

#main {
}

 #main_top {
//	@extend .sticky-top;
//	background-color: ;
//	background-color: rgba(255,255,255, 0.8);
}

#top {
}

#middle {
}

#middle_top {
}

#middle_left, #middle_right {

	.widget-grid {
		@include widget-toggle($widget-toggle);
	}

	.widget_nav_menu {
		@include menu();

		div > ul > li { // Menu level 1 format
//			@extend .text-secondary;

			& > a {
/* left/right column menu hover.css { *
				@include underline-from-left();
				display: block;

				&:before {
//					background-color: $brand-secondary;
				}
/* } */
			}
		}

		div > ul > li > ul > li { // Menu level 2 format
//			@extend .text-secondary;
		}
	}
}

#middle_left {

	.widget-grid {
		@include widget-toggle();
	}
}

#middle_center {
}

#middle_center_top {

}

#content {

	img {
		@extend .img-fluid;
	}

	._key, .items_key {
		clear: both;
		float: left;
		margin: 0;
		margin-top: $grid-gutter-width / 2;

		&:after {
			display: inline-block;
			content: ":";
			margin-right: 3px;
		}

		& + div {

			& + .items_val {
				clear: both;
			}
		}

		&:first-child {
			margin-top: 0;
		}
	}

	._val, .items_val {
		margin: 0;
		margin-top: $grid-gutter-width / 2;
		@include clearfix();
		clear: right;

		&:first-child {
			margin-top: 0;
		}
	}

	._icon_val {
		float: left !important;
		max-width: $icon-width + if(unit($icon-width) == px and $icon-edging, 10, 0);
		width: 100%;

		@if $icon-width == 0 {
			display: none;
		}

		@if $icon-edging {
			@extend .edging-simple;
		}

		@if $icon-height {
			max-height: $icon-height;
		}

		.no-image {
			max-height: if($icon-height, $icon-height, if(unit($icon-width) == px, $icon-width * 9 / 16, 200px));
		}

		img {
			object-fit: cover;
			max-width: $icon-width + if(unit($icon-width) == px and $icon-edging, 10, 0);
			width: 100%;
			@if $icon-height {
				max-width: none !important;
				max-height: $icon-height;
			}
		}
	}

	._block_main_open {
		float: left !important;
		width: 100%;

		@each $key, $val in $items-per-row {

			@if(unit($icon-width) == px and $val <= 1 and ($key == 'xl' or $key == 'lg')) {

				@include media-breakpoint-only($key) {
					width: calc(100% - #{$icon-width + if(unit($icon-width) == px and $icon-edging, 10, 0) + ($grid-gutter-width)});
					margin-left: $grid-gutter-width;
				}
			}
		}

/* item block edging { *
		border: 1px solid $gray-200;
		background-color: $gray-100;
		padding: $grid-gutter-width / 2 $grid-gutter-width / 2 0 $grid-gutter-width / 2;
/* } */
		& + div {
			clear: both;
		}
	}

	._block_bg_open, .items_block_bg_open {
		background-color: $gray-100;
		margin-top: $grid-gutter-width / 2;
		padding: $grid-gutter-width / 2;
		@include clearfix();

		&>* {
			margin-bottom: 0;
		}
	}

	._header_val, .items_header_val {
		margin-left: 0;
		margin-right: 0;
	}

	._date_val, .items_date_val,
	._dateauthor_val, .items_dateauthor_val,
	._author_val, .items_author_val {
//		margin-right: $grid-gutter-width;
		font-style: italic;
		color: $gray-500;
	}

	._lead_val {
	}

	._categories_val {
	}

	._tags_val, .items_tags_val {

		a {
			&:after {
						content: ',';
			}

/* item/items tags labeling { *
			@extend .badge;
			@extend .badge-default;
//			@include btn('primary', 'sm');
			@include fas("\f02b", 0);

			&:after {
				content: none;
			}
/* } */
		}
	}

	._comments_val {

		.navigation {

			.alignleft,.alignright {
				margin: 0;
			}

			@include clearfix();
		}
	}

	._content_val {
		clear: both;
	}

	._items_val {
		@include make-row();
//		margin-top: $grid-gutter-width / -2;
//		margin-bottom: $grid-gutter-width / -2;
		justify-content: $items-justify-content;

		.items_item {
			padding: $grid-gutter-width / 2;
			width: 100%;

			@each $key, $val in $items-per-row {

				@include media-breakpoint-only($key) {
					width: (100 / $val) + '%';
				}
			}

			@if $items-edging / ($items-edging * 0 + 1) > 0 {

				margin: 0;
				padding-top: $grid-gutter-width / 2;
				padding-bottom: $grid-gutter-width / 2;

				& > .inner {
//					@include clearfix;
					border: 1px solid $gray-200;
					padding: $items-edging;
//					padding-bottom: $items-edging - ($grid-gutter-width / 2);
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}
			}
			@else {

				margin: 0;
				padding-top: $grid-gutter-width / 2;
//				padding-bottom: 0;

				& > .inner {
//					@include clearfix;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}

				@each $key, $val in $items-per-row {

					@include media-breakpoint-only($key) {
						&:nth-child(n+#{$val + 1}) > .inner {
							border-top: 1px solid $gray-200;
							padding-top: $grid-gutter-width;
						}
					}
				}
			}
		}
	}

	.inner>.items_val:last-child {
		margin-bottom: 0;
	}

	.items_icon_val {
//		float: left !important;
		max-width: $items-icon-width + if(unit($items-icon-width) == px and $items-icon-edging, 10, 0);
		width: 100%;
		align-self: start;

		@each $key, $val in $items-per-row {

			@if(unit($items-icon-width) == px and $val <= 1 and ($key == 'md' or $key == 'xl' or $key == 'lg')) {

				@include media-breakpoint-only($key) {
//					margin-right: $grid-gutter-width / 2;
				}
			}
		}

		@if $items-icon-width == 0 {
			display: none;
		}

		@if $items-icon-edging {
			@extend .edging-simple;
		}

		@if $items-icon-height {
			max-height: $items-icon-height;
		}

		.no-image {
			max-height: if($items-icon-height, $items-icon-height, if(unit($items-icon-width) == px, $items-icon-width * 9 / 16, 200px));
		}

		img {
			object-fit: cover;
//			max-width: $items-icon-width + if(unit($items-icon-width) == px and $items-icon-edging, 10, 0);
			min-width: 100%;
//			min-height: 100%;
			@if $items-icon-height {
//				max-width: none !important;
//				max-height: $items-icon-height;
			}
		}
	}

	.items_block_main_open {
//		float: left !important;
		width: 100%;
//		min-width: 300px;
		display: flex;
		flex-direction: column;

		@each $key, $val in $items-per-row {

			@if(unit($items-icon-width) == px and $val <= 1 and ($key == 'xl' or $key == 'lg')) {

				@include media-breakpoint-only($key) {
					width: calc(100% - #{$items-icon-width + if(unit($items-icon-width) == px and $items-icon-edging, 10, 0) + ($grid-gutter-width)});
				}
			}
		}

/* items block edging { *
		border: 1px solid $gray-200;
		background-color: $gray-100;
		padding: $grid-gutter-width / 2 $grid-gutter-width / 2 0 $grid-gutter-width / 2;
/* } */
		& + div {
			clear: both;
		}
	}

	.items_more_val {
		text-align: right;
		margin-top: auto;
		width: 100%;

		a {
/* items more button { */
//			@include fa-after("\f101", 0.5em, inherit, auto);
			@include btn(primary, sm, true);
/* } */
		}
	}

	._items_pagination_val {
		@include clearfix;
		background-color: $gray-100;
		padding: $grid-gutter-width / 2;
		color: $gray-600;

		.pagination_next {
			float: left;
			width: 15%;
			text-align: left;

			& > a, & > span {
//				@include btn();
				@include fas("\f060", 0);
				span { display: none; }
			}

			& > span {
				cursor: not-allowed;
//				@include opacity(.65);
				@include box-shadow(none);
			}
		}

		.pagination_pages {
			float: left;
			text-align: center;
			width: 70%;
		}

		.pagination_prev {
			float: right;
			width: 15%;
			text-align: right;

			& > a, & > span {
//				@include btn();
				@include fas("\f061", 0);
				span { display: none; }
			}

			& > span {
				cursor: not-allowed;
//				@include opacity(.65);
				@include box-shadow(none);
			}
		}
	}
}

#middle_right {

	.widget-grid {
		@include widget-toggle();
	}
}

#middle_bottom {
}

#bottom {
}

#main_bottom {
}

#body_bottom {
}


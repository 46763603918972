
@each $key, $val in $grid-breakpoints {

	@include media-breakpoint-only($key) {

		.position-#{$key}-static {

			position: absolute;
		}

		.position-#{$key}-relative {

			position: absolute;
		}

		.position-#{$key}-absolute {

			position: absolute;
		}

		.position-#{$key}-fixed {

			position: absolute;
		}
	}
}

.position-x-auto {
	left: 50%;
	transform: translate(-50%);
}

.position-y-auto {
	top: 50%;
	transform: translate(0%,-50%);
}

.position-xy-auto {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.position-top {
	top: 0;
}

.position-bottom {
	bottom: 0;
}

.position-left {
	left: 0;
}

.position-right {
	right: 0;
}

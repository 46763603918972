
@mixin ul-icon-fix($padding: 1.5em, $margin: -1.5em) {
	padding-left: $padding !important;

	&::before {
		margin-left: $margin !important;
	}
}

@function pow($base, $exp) {

	$val: 1;

	@if $exp > 0 {

		@for $i from 1 through $exp {

			$val: $val * $base;
		}
	}
	@else if $exp < 0 {

		@for $i from 1 through -$exp {

			$val: $val / $base;
		}
	}

	@return $val;
}

@function luminance($color) {

	$colors: (
		'red': red($color),
		'green': green($color),
		'blue': blue($color)
	);

	@each $key, $val in $colors {

		$adjusted: 0;
		$val: $val / 255;

		@if $val < 0.03928 {

			$val: $val / 12.92;
		}
		@else {

			$val: ($val + .055) / 1.055;
			$val: pow($val, 2.4);
		}

		$colors: map-merge($colors, ($key: $val));
	}

	@return (map-get($colors, 'red') * .2126) + (map-get($colors, 'green') * .7152) + (map-get($colors, 'blue') * .0722);
}

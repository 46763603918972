
//	.menu
//	.menu-hover
//	.menu-icon
//	.menu-noindent
//	.menu-noindent-{$levels}
//	.menu-noindent-{$grid-breakpoints}-#{$levels}
//	.menu-collapse-{$grid-breakpoints}
//	.menu-horizontal-{$levels}
//	.menu-horizontal-{$grid-breakpoints}-{$levels}
//	.menu-vertical-{$levels}
//	.menu-vertical-{$grid-breakpoints}-{$levels}
//	.menu-show-{$levels}
//		.menu-dropdown-{$levels}
//	.menu-show-{$grid-breakpoints}-{$levels}
//		.menu-dropdown-{$grid-breakpoints}-{$levels}
//	.menu-noicon-#{$levels}
//	.menu-noicon-{$grid-breakpoints}-#{$levels}

@mixin menu {

	div {

		ul {
			@include clearfix();
			padding-left: 0;
			list-style-type: none;
			margin-top: 0;
			margin-bottom: 0;
			padding-left: 1.5em;

			& > li {
				@include clearfix();
				display: list-item;
				float: none;

				& > a , & > span {
					color: inherit;
					display: block;
					outline: none;
					padding: .3em .5em;
				}


			}
		}
	}
}

.menu {
	@include menu;
}

@each $key, $val in $grid-breakpoints {

	@include media-breakpoint-only($key) {

		.menu-collapse-#{$key} {

			.collapse-icon {
				display: inline-block;
			}

			div {
				position: relative;
			}

			div > ul {
				clear: both;
				display: none;
				z-index: $zindex-dropdown;
				background-color: #fff;
				color: #444 !important;
				position: absolute;
				right: $grid-gutter-width / 2;
				padding-top: .5em;
				padding-bottom: .5em;
				border: 1px solid rgba(0,0,0,0.1);
				width: 180px;
				right: 0;
			}

			div ul > li > a {
				padding-left: 1em;
				padding-right: 1em;
			}

			div > ul.shown {
				display: block;
				border: 1px solid rgba(0,0,0,0.1);
			}
		}

		.menu-divide-#{$key} {

			div ul > li {

				border-bottom: 1px solid $gray-200;

				&:first-child {
					border-top: 1px solid $gray-200;
				}
			}
		}
	}
}

@include media-breakpoint-down(xs) {

	.menu-divide-xs {

		div ul > li {
			border-bottom: 1px solid $gray-200;

			&:first-child {
				border-top: 1px solid $gray-200;
			}
		}
	}
}

.menu-divide {

	div ul > li {
		border-bottom: 1px solid $gray-200;

		&:first-child {
			border-top: 1px solid $gray-200;
		}
	}
}

.collapse-icon {
	background: transparent;
	border-width: 0;
	font-size: 32px;
	padding: 5px;
	display: none;
	line-height: 32px;
	color: inherit;
	@include fas('\f0c9', 0, inherit);

	&:link,
	&:focus,
	&:active,
	&:visited,
	&:hover {
		background-color: transparent;
		color: inherit;
		box-shadow: none;
	}
}

@for $i from 1 through $menu-levels {

	$selector: 'div';

	@for $ii from 1 through $i {

		$selector: $selector + ' > ul > li';
	}

	.menu-horizontal-#{$i} {

		#{$selector} {
			display: block;
			float: left;
			clear: none;
		}
	}

	@each $key, $val in $grid-breakpoints {

		@include media-breakpoint-only($key) {

			.menu-horizontal-#{$key}-#{$i} {

				#{$selector} {
					display: block;
					float: left;
					clear: none;
				}
			}
		}
	}

	.menu-vertical-#{$i} {

		#{$selector} {
			display: list-item;
			float: none;
			clear: none;
		}
	}

	@each $key, $val in $grid-breakpoints {

		@include media-breakpoint-only($key) {

			.menu-vertical-#{$key}-#{$i} {

				#{$selector} {
					display: list-item;
					float: none;
					clear: none;
				}
			}
		}
	}

	.menu-show-#{$i} {

		#{$selector + ' > ul'} {
			display: none;
		}

		&.menu-dropdown-#{$i} {

			#{$selector} {
				position: relative;
			}

			#{$selector + ' > ul'} {
				float: none;
				z-index: $zindex-dropdown + $i;
				background-color: #fff;
				color: #444 !important;
				position: absolute;
				padding-top: .5em;
				padding-bottom: .5em;
				border: 1px solid rgba(0,0,0,0.1);
				width: 180px;
				left: 50%;
				margin-left: -90px;
			}

			#{$selector + ' > ul > li > a'} {
				padding-left: 1em;
				padding-right: 1em;
			}
		}
	}

	.menu-noicon-#{$i} {

		#{$selector + ' > a > span:before'} {
			content: none;
		}
	}

	.menu-noindent-#{$i} {

		#{str-slice($selector, 0, -5)} {
			padding-left: 0 !important;
		}
	}

	@each $key, $val in $grid-breakpoints {

		@include media-breakpoint-only($key) {

			.menu-show-#{$key}-#{$i} {

				#{$selector + ' > ul'} {
					display: none;
				}

				&.menu-dropdown-#{$key}-#{$i} {

					#{$selector} {
						position: relative;
					}

					#{$selector + ' > ul'} {
						float: none;
						z-index: $zindex-dropdown + $i;
						background-color: #fff;
						color: #444 !important;
						position: absolute;
						padding-top: .5em;
						padding-bottom: .5em;
						border: 1px solid rgba(0,0,0,0.1);
						width: 180px;
						left: 50%;
						margin-left: -90px;
					}

					#{$selector + ' > ul > li > a'} {
						padding-left: 1em;
						padding-right: 1em;
					}
				}
			}

			.menu-noicon-#{$key}-#{$i} {

				#{$selector + ' > a > span:before'} {
					content: none;
				}
			}

			.menu-noindent-#{$key}-#{$i} {

				#{str-slice($selector, 0, -5)} {
					padding-left: 0 !important;
				}
			}
		}
	}
}

.menu-icon {

	div ul > li > a > span:before {
		content: '\00BB';
		display: inline-block;
		font-size: 1.4em;
		width: 1em;
		line-height: 1em;
		text-align: center;
		color: map-get($theme-colors, primary);
	}

	div ul > li > a:hover > span:before {
		color: inherit;
	}
}

.menu-noindent {

	div ul {
		padding-left: 0 !important;
	}
}



//
// toggles widgets by breakpoint (requires JS for onclick toggle)
//
@mixin widget-toggle($breakpoint: $widget-toggle) {

	@include media-breakpoint-down($breakpoint) {

		.widget-body {

			& > * {
				display: none;
			}

			.widget_label {
				display: block;
				position: relative;
//				margin: $grid-gutter-width / 2 0;
				cursor: pointer;
			}
		}

		&.active {

			.widget-body > * {
				display: block;
			}
		}
	}

	@include media-breakpoint-up(breakpoint-next($breakpoint, $grid-breakpoints)) {

		.widget-body {

			& > .widget_label + * {
				display: block;
			}

			.widget_label {
				pointer-events: none;
			}
		}
	}
}

.widget-toggle {
	@include widget-toggle();
}

.widget-body.widget-noicon {
	& > .widget_label::before {
		display: none;
	}
}

.widget-body.widget-nolabel {
	& > .widget_label {
		display: none;
	}
}

.widget-body.widget-nolabel-divide {
	& > .widget_label {
		border-bottom: 1px solid #eee;
		margin-bottom: .3em;
	}
}

@each $key, $val in $grid-breakpoints {

	@include media-breakpoint-only($key) {

		.widget-body.widget-nolabel-#{$key} {

			& > .widget_label {
				display: none;
			}
		}
	}
}


//	.text-shadow-black-sm
//	.text-shadow-black
//	.text-shadow-black-lg
//	.text-shadow-white-sm
//	.text-shadow-white
//	.text-shadow-white-lg
//	.text-shadow-none
//	.shadow-bottom-sm
//	.shadow-bottom
//	.shadow-bottom-lg
//	.shadow-vertical-sm
//	.shadow-vertical
//	.shadow-vertical-lg
//	.shadow-horizontal-sm
//	.shadow-horizontal
//	.shadow-horizontal-lg

.text-shadow-black-sm {
	text-shadow: $text-shadow-black-sm;
}

.text-shadow-black {
	text-shadow: $text-shadow-black;
}

.text-shadow-black-lg {
	text-shadow: $text-shadow-black-lg;
}

.text-shadow-white-sm {
	text-shadow: $text-shadow-white-sm;
}

.text-shadow-white {
	text-shadow: $text-shadow-white;
}

.text-shadow-white-lg {
	text-shadow: $text-shadow-white-lg;
}

.text-shadow-none {
	text-shadow: none;
}

.text-stroke-black {
	text-shadow: $text-stroke-black;
}

.text-stroke-white {
	text-shadow:  $text-stroke-white;
}

.shadow-bottom-sm {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 0 0px 20px $shadow-color;
		top: 50%;
		bottom: 0;
		left: 20px;
		right: 20px;
		border-radius: 100px / 10px;
	}
}

.shadow-bottom {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 0 5px 20px $shadow-color;
		top: 50%;
		bottom: 0;
		left: 20px;
		right: 20px;
		border-radius: 100px / 10px;
	}
}

.shadow-bottom-lg {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 0 10px 20px $shadow-color;
		top: 50%;
		bottom: 0;
		left: 20px;
		right: 20px;
		border-radius: 100px / 10px;
	}
}

.shadow-vertical-sm {
	position: relative;

	&::before{
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 0 0px 20px $shadow-color;
		top: 0;
		bottom: 50%;
		left: 20px;
		right: 20px;
		border-radius: 100px / 10px;
	}

	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 0 0px 20px $shadow-color;
		top: 50%;
		bottom: 0;
		left: 20px;
		right: 20px;
		border-radius: 100px / 10px;
	}
}

.shadow-vertical {
	position: relative;

	&::before{
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 0 -5px 20px $shadow-color;
		top: 0;
		bottom: 50%;
		left: 20px;
		right: 20px;
		border-radius: 100px / 10px;
	}

	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 0 5px 20px $shadow-color;
		top: 50%;
		bottom: 0;
		left: 20px;
		right: 20px;
		border-radius: 100px / 10px;
	}
}

.shadow-vertical-lg {
	position: relative;

	&::before{
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 0 -10px 20px $shadow-color;
		top: 0;
		bottom: 50%;
		left: 20px;
		right: 20px;
		border-radius: 100px / 10px;
	}

	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 0 10px 20px $shadow-color;
		top: 50%;
		bottom: 0;
		left: 20px;
		right: 20px;
		border-radius: 100px / 10px;
	}
}

.shadow-horizontal-sm {
	position: relative;

	&::before{
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 0px 0 20px $shadow-color;
		top: 20px;
		bottom: 20px;
		left: 0;
		right: 50%;
		border-radius: 100px / 10px;
	}

	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 0px 0 20px $shadow-color;
		top: 20px;
		bottom: 20px;
		left: 50%;
		right: 0;
		border-radius: 100px / 10px;
	}
}

.shadow-horizontal {
	position: relative;

	&::before{
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: -5px 0 20px $shadow-color;
		top: 20px;
		bottom: 20px;
		left: 0;
		right: 50%;
		border-radius: 100px / 10px;
	}

	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 5px 0 20px $shadow-color;
		top: 20px;
		bottom: 20px;
		left: 50%;
		right: 0;
		border-radius: 100px / 10px;
	}
}

.shadow-horizontal-lg {
	position: relative;

	&::before{
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: -10px 0 20px $shadow-color;
		top: 20px;
		bottom: 20px;
		left: 0;
		right: 50%;
		border-radius: 100px / 10px;
	}

	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		box-shadow: 10px 0 20px $shadow-color;
		top: 20px;
		bottom: 20px;
		left: 50%;
		right: 0;
		border-radius: 100px / 10px;
	}
}

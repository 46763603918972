
@mixin fa($icon: "", $color: inherit, $width: 1.5em) {
	display: inline-block;
	font-family: FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: $icon;
	color: $color;
	width: $width;
	text-align: center;
}

@mixin far($icon: "", $margin-right: $line-height-base / 2, $color: inherit, $width: 1.5em) {

	&::before {
		@include fa($icon, $color, $width);
		margin-right: $margin-right;
	}
}

@mixin far-after($icon: "", $margin-left: $line-height-base / 2, $color: inherit, $width: 1.5em) {

	&::after {
		@include fa($icon, $color, $width);
		margin-left: $margin-left;
	}
}

@mixin fas($icon: "", $margin-right: $line-height-base / 2, $color: inherit, $width: 1.5em) {

	&::before {
		@include fa($icon, $color, $width);
		margin-right: $margin-right;
		font-weight: 900;
	}
}

@mixin fas-after($icon: "", $margin-left: $line-height-base / 2, $color: inherit, $width: 1.5em) {

	&::after {
		@include fa($icon, $color, $width);
		margin-left: $margin-left;
		font-weight: 900;
	}
}

@mixin fab($icon: "", $margin-right: $line-height-base / 2, $color: inherit, $width: 1.5em) {

	&::before {
		@include fa($icon, $color, $width);
		margin-right: $margin-right;
	}
}

@mixin fab-after($icon: "", $margin-left: $line-height-base / 2, $color: inherit, $width: 1.5em) {

	&::after {
		@include fa($icon, $color, $width);
		margin-left: $margin-left;
	}
}

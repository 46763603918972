
// TODO: Remove dependency of font-awesome
// TODO: configuration variables

@mixin numlist($size: 3em) {
	list-style-type: none;
	padding-left: 0;
	counter-reset: numlist;
	text-align: left;

	& > li {
		@include fas("");
		@include ul-icon-fix($size * 1.6);
		counter-increment: numlist;
		clear: both;
		min-height: $size;
		display: flex;
		align-items: center;
		margin-bottom: $size / 5;

		&:before {
			content: counter(numlist);
			font-size: $size;
			line-height: 1.5em;
			min-width: 1.6em;
//			background-color: #eee;
			border: 1px solid #aaa;
			border-radius: 50%;
			margin-right: .2em;
			align-self: start;
		}
	}
}

.numlist-sm {
	@include numlist(1em);
}

.numlist {
	@include numlist(3em);
}

.numlist-lg {
	@include numlist(3em);
}

.checklist {
	list-style-type: none;
	padding-left: 0;
	& > li {
		@include fas("\f00c", 0, map-get($theme-colors, primary));
		@include ul-icon-fix(2em);
		&:before {
			font-size: 24px;
		}
	}
}


@mixin layout() {

	#body_top > .inner,
	#main_top > .inner,
	#top > .inner,
	#bottom > .inner,
	#main_bottom > .inner,
	#body_bottom > .inner {
		@extend .container;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		padding: 0;
		position: relative;
	}

	#middle_top,
	#middle_center_top,
	#middle_center_bottom,
	#middle_bottom {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	#middle > .inner {
		@extend .container;
		padding: $column-wrap $column-wrap $column-wrap $column-wrap;
	}

	#columns {
		display: flex;

		@include media-breakpoint-down(sm) {

			flex-direction: column;
		}
	}

	#middle_left {
		display: block;
		position: relative;
		min-height: 1px;
		width: 100%;

		&:empty {
			display: none;
		}

		@each $key, $val in $column-left-width {

			@include media-breakpoint-only($key) {

				@if $val == 0 {
					display: none;
				}
				@else {
					max-width: $val;
					min-width: $val;
				}
			}
		}
	}

	#middle_center {
		display: block;
		position: relative;
		min-height: 1px;
		overflow: hidden;
		width: 100%;
	}

	#content {
		padding: $grid-gutter-width / 2;
	}

	#middle_right {
		display: block;
		position: relative;
		min-height: 1px;
		width: 100%;

		&:empty {
			display: none;
		}

		@each $key, $val in $column-right-width {

			@include media-breakpoint-only($key) {

				@if $val == 0 {
					display: none;
				}
				@else {
					max-width: $val;
					min-width: $val;
				}
			}
		}
	}
}


@mixin sweep-up-out($bg-color: $gray-200, $color: $black) {

	& {
		position: relative;
		z-index: 1;
	}

	&:hover {
		color: $color;
		transition: all 0.5s ease;
	}

	&:before {
		background-color: $bg-color;
		position: absolute;
		height: 0%;
		bottom: 0%;
		left: 50%;
		right: 50%;
		content: '';
		z-index: -1;
		opacity: 0;
		transition: all 0.3s ease;
		transition-delay: 0.15s;
	}

	&:hover:before {
		left: 0;
		right: 0;
		height: 100%;
		opacity: 1;
		transition-delay: 0.3s;
	}
}

@mixin cube-down($bg-color: $gray-200, $color: $black) {

	& {
		backface-visibility: hidden;
		display: block;
		position: relative;
		text-decoration: none;
		padding: 5px;
		transform-style: preserve-3d;
		transform-origin: 50% 50%;
		transition: all 0.2s linear;
	}

	&:after {
		content: attr(data-hover);
		height: 100%;
		bottom: 100%;
		position: absolute;
		left: 0;
		z-index: -1;
		width: 100%;
		padding: 5px;
		transform: translateY(0%) rotateX(90deg);
		transform-origin: 50% 100%;
		transition: all 0.2s linear;
	}

	&:hover {
		color: transparent;
		transform: translateY(50%) rotateX(-90deg);
	}

	&:hover:after {
		background-color: $bg-color;
		color: $color;
		cursor: pointer;
	}
}
// http://animista.net/play/basic/flip/flip-horizontal-bottom
/*
.flip-horizontal-bottom {
	animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@keyframes flip-horizontal-bottom {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(-180deg);
  }
}
/**/
